import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import vco from "v-click-outside"

import App from './App.vue'
import router from './router'
import VueFeather from 'vue-feather'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueEasyLightbox from 'vue-easy-lightbox'
import VueAlertify from "vue-alertify"
import VueMask from 'v-mask'
import VueQuillEditor from 'vue-quill-editor'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueVectorMap from 'vuevectormap'
import i18n from './i18n'

import store from '@/state/store'
import { Application } from "@/global";

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
//import 'vue2-datepicker/locale/ko';
import 'vue2-datepicker/locale/ja';
import moment from 'moment';

Vue.config.productionTip = false

// Common Function
import common from '@/assets/js/common';
import formatter from '@/assets/js/formatter';
Vue.use(common);
Vue.use(formatter);


import '@/assets/scss/app.scss';
import '@/assets/scss/app_csms.scss';

Vue.use(BootstrapVue);
Vue.use(vco);
Vue.use(VueFeather);

Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueEasyLightbox)
Vue.use(VueAlertify)
Vue.use(VueMask)
Vue.use(VueQuillEditor)
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_API_KEY || '',
    libraries: 'places',
  },
  installComponents: true
})
Vue.use(VueVectorMap)
Vue.use(DatePicker)
window.moment = Vue.prototype.$moment = moment

Vue.prototype.$_app = Vue.observable(new Application(router));

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
