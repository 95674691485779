import store from '@/state/store';

export default [
    {
        path: '/',
        name: 'Home',
        redirect: '/dashboard',
        meta: {
            authRequired: false,
            authorization: []
        },
        component: () => import('@/views/layouts/Blank.vue')
    },
    {
        path: '/dashboard',
        name: 'DashBoard',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/dashboards/Dashboard.vue')
    },
    {
        path: '/manager/list',
        name: 'ManagerList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ']
        },
        component: () => import('@/views/pages/manager/ManagerList.vue')
    },
    {
        path: '/hq/list',
        name: 'HeadquartersList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ']
        },
        component: () => import('@/views/pages/headquarters/HqList.vue')
    },
    {
        path: '/hq/view/:id',
        name: 'HeadquartersView',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ']
        },
        component: () => import('@/views/pages/headquarters/HqView.vue')
    },
    {
        path: '/charge/place/list',
        name: 'ChargePlaceList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/charge-place/CpList.vue')
    },
    {
        path: '/charge/place/view/:id',
        name: 'ChargePlaceView',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/charge-place/CpView.vue')
    },
    {
        path: '/charge/point/list',
        name: 'ChargePointList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/charge-box/CbList.vue')
    },
    {
        path: '/charge/price/list',
        name: 'ChargePriceList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/payment/PricePlanList.vue')
    },
    {
        path: '/charge/output/list',
        name: 'ChargeOutputList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/charge-output/ChargeOutputList.vue')
    },
    {
        path: '/plan/member/list',
        name: 'MemberPlanList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/plan/MemberPlanList.vue')
    },
    {
        path: '/plan/business/list',
        name: 'BusinessPlanList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/plan/BusinessPlanList.vue')
    },
    {
        path: '/charge/history/list',
        name: 'ChargeHistoryList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/charge-history/ChargeHistoryList.vue')
    },
    {
        path: '/plan/history/list',
        name: 'PlanHistoryList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/plan/PlanHistoryList.vue')
    },
    {
        path: '/settlement/list',
        name: 'SettlementList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/settlement/SettlementList.vue')
    },
    {
        path: '/point/list',
        name: 'PointList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/point/PointList.vue')
    },
    {
        path: '/app/member/list',
        name: 'AppUserList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/member/MemberList.vue')
    },
    {
        path: '/app/member/view/:id',
        name: 'AppUserView',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/member/MemberView.vue')
    },
    {
        path: '/app/ad/list',
        name: 'AdvertisementList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ']
        },
        component: () => import('@/views/pages/advertisement/AdList.vue')
    },
    {
        path: '/system/notice/list',
        name: 'NoticeList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/notice/NoticeList.vue')
    },
    {
        path: '/system/terms/list',
        name: 'TermsPolicyList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/terms/TermsList.vue')
    },
    {
        path: '/system/notification/list',
        name: 'NotificationList',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/system/NotificationList.vue')
    },
    {
        path: '/system/log/real',
        name: 'RealLog',
        meta: {
            authRequired: true,
            authorization: ['ADMIN']
        },
        component: () => import('@/views/pages/system/RealLogViewModal.vue')
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
            authorization: ['ADMIN', 'HQ', 'CP']
        },
        component: () => import('@/views/pages/account/Profile.vue')
    },
    {
        path: '/signup',
        name: 'SignUp',
        component: () => import('@/views/pages/account/SignUp.vue')
    },
    {
        path: '/verification',
        name: 'EmailVerification',
        component: () => import('@/views/pages/account/EmailVerification.vue')
    },
    {
        path: '/login',
        name: 'SignIn',
        component: () => import('@/views/pages/account/SignIn.vue')
    },
    {
        path: '/find-password',
        name: 'FindPassword',
        component: () => import('@/views/pages/account/FindPassword.vue')
    },
    {
        path: '/oauth2/redirect',
        name: 'OAuth2RedirectHandler',
        component: () => import('@/oauth/OAuth2RedirectHandler.vue')
    }
]
