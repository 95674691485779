import i18n from '@/i18n';

const utils = {
  isEmpty(val) {
    return val === null || val === undefined || val === 'undefined' || typeof val === 'undefined' || String(val).trim() === '';
  },
  textLengthOverCut(text, len, lastText) {
    len = this.isEmpty(len) ? 20 : len;
    lastText = this.isEmpty(lastText) ? '...' : lastText;

    if (!this.isEmpty(text) && text.length > len) {
      text = text.slice(0, len) + lastText;
    }
    return text;
  },
  inDays(dateStr, day) {
    const createdDate = new Date(dateStr);
    const currentDate = new Date();

    const timeDifference = currentDate - createdDate;
    const dayDifference = timeDifference / (1000 * 60 * 60 * 24);

    return dayDifference <= day;
  },
  comma(val) {
    if (typeof val === 'undefined') val = 0;

    const sValue = String(val);
    let parts = sValue.split('.');
    let regex = new RegExp(`\\B(?=(\\d{${3}})+(?!\\d))`, 'g');

    return parts[0].replace(regex, ',') + (parts[1] ? '.' + parts[1] : '');
  },
  nowDate() {
    const today = new Date();

    const year = today.getFullYear();
    const month = ('0' + (today.getMonth() + 1)).slice(-2);
    const day = ('0' + today.getDate()).slice(-2);

    return year + '-' + month  + '-' + day;
  },
  nowDay() {
    const today = new Date();

    const weekDay = [
        i18n.t('common.sunday'),
        i18n.t('common.monday'),
        i18n.t('common.tuesday'),
        i18n.t('common.wednesday'),
        i18n.t('common.thursday'),
        i18n.t('common.friday'),
        i18n.t('common.saturday')
    ];

    return weekDay[today.getDay()];
  },
  nowTime() {
    const today = new Date();

    const hours = ('0' + today.getHours()).slice(-2);
    const minutes = ('0' + today.getMinutes()).slice(-2);
    const seconds = ('0' + today.getSeconds()).slice(-2);

    return hours + ':' + minutes  + ':' + seconds;
  }
}

export default {
  install(Vue) {
    Vue.prototype.$_utils = utils;
  }
}
