export const state = {
  isLoading: false
}

export const mutations = {
  SET_LOADING(state, visibility) {
    state.isLoading = visibility;
  }
}

export const actions = {
  showOverlay({ commit }) {
    commit('SET_LOADING', true);
  },
  hideOverlay({ commit }) {
    commit('SET_LOADING', false);
  }
}

export const getters = {
  isLoading: state => state.isLoading
};
