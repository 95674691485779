<template>
  <div id="app">
    <b-overlay :show="$store.state.loading.isLoading" rounded="sm" style="position: unset !important;">
      <router-view />
      <template #overlay>
        <div class="text-center">
          <b-spinner variant="primary" style="width: 2.5rem; height: 2.5rem;" />
          <div>Loading...</div>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import appConfig from "@/app.config";

export default {
  name: 'App',
  page: {
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${appConfig.title} | ${title}` : appConfig.title;
    }
  },
};
</script>
