import { AuthApi, http } from '@/auth-api';

async function getAllCode() {
    const res = await http.get(`/onmapi/utils/code/all`)

    return res.data;
}

export class Application extends AuthApi {
    constructor(router) {
        super(router, "/login");
        this.code = {}

        getAllCode().then(res => {
            this.code = res;
        });
    }
}
