const unsecured_http = require('axios');

const ID_TOKEN_KEY = "jwt_token"

export let http = unsecured_http;
let g_userInfo = {};
let g_access_token= null;
let g_loginHandler = null;

function decodeToken(jwt) {
    try {
        let base64Url = jwt.split('.')[1]
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        let jsonToken = JSON.parse(window.atob(base64));
        return jsonToken;
    }
    catch (e) {
        console.log(e);
        return null;
    }
}

function createHttpConnection() {
    g_access_token = getToken();

    if (g_access_token) {
        g_userInfo = decodeToken(g_access_token);
    }

    if (g_userInfo != null) {
        if (g_loginHandler != null) {
            g_loginHandler.setWebToken(g_userInfo);
        }

        http = unsecured_http.create({
            headers: { "Authorization": 'Bearer ' + g_access_token }
        })
    } else {
        http = unsecured_http;
    }

    http.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log('reject!!!!!!!!!!!', error)
        if (error.response?.status === 401 && g_loginHandler != null) {
            g_loginHandler.showLogin()
        }
        return Promise.reject(error);
    });
}

function getToken() {
    return window.localStorage.getItem(ID_TOKEN_KEY)
}

function saveToken(token) {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
    g_access_token = token;
    createHttpConnection();
}

function destroyToken() {
    window.localStorage.removeItem(ID_TOKEN_KEY)
}

export function auth_checkUserAuthority(authorization) {
    if (!g_loginHandler) return true;

    return g_loginHandler.checkAuthority(authorization)
}

export class AuthApi {
    constructor(router, loginRedirectUrl) {
        const vm = this;
        g_loginHandler = this;
        vm.loginRedirectUrl = loginRedirectUrl
        vm.router = router;
        vm.user = {};

        createHttpConnection();

        router.beforeEach((to, from, next) => {
            const { authorization } = to.meta


            if (!vm.checkAuthority(authorization)) {
                /*console.log('!checkAuthority');
                if (vm.user.email == null || vm.user.password == null) {
                    return next({ path: loginRedirectUrl });
                }
                alert('사용 권한이 없습니다.')
                return next({ path: loginRedirectUrl });*/


                if(!g_userInfo?.roles || g_userInfo?.roles.length == 0) {
                    //alert("사용 권한이 없습니다.\n관리자에게 문의 바랍니다.")
                    return next({ path: loginRedirectUrl });
                } else {
                    alert("메뉴 권한이 없습니다.")
                    return
                }
            }
            next();
        });
    }

    checkAuthority(authorization) {

        if (!authorization || authorization.length == 0) return true;

        for (const role of authorization) {
            if (g_userInfo?.roles?.includes(role)) {
                return true;
            }
        }
        return false;
    }

    showLogin() {
        this.router.push(this.loginRedirectUrl);
    }

    setWebToken(jwt) {
        this.user = jwt;
    }

    async signIn(email, password) {
        let res = await unsecured_http.post('/auth/login', {
            email, password
        });
        saveToken(res.data.accessToken)

        return res.data;
    }

    async signUp(_form) {
        let res = await unsecured_http.post('/auth/signup', _form);
        return res.data;
    }

    async findPwd(to) {
        let res = await unsecured_http.post('/onmapi/Email/VerifyEmailForReset/w', {
           to
        });
        return res.data;
    }

    signOut() {
        destroyToken();
    }

    setAccessToken(token) {
        saveToken(token);
    }
}
