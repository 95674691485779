import i18n from '@/i18n';

const formatter = {
  chargingConfigTypeName(str) {
    switch (str) {
      case 'capacity': return i18n.t('common.configCapacity');
      case 'time': return i18n.t('common.configTime');
      case 'price': return i18n.t('common.configPrice');
      case 'soc': return i18n.t('common.configSoc');
      default: return '-';
    }
  },
  conversionPlanName(str) {
    switch (str) {
      case 'Normal Plan': return i18n.t('common.generalPlan');
      case 'Ultra Plan': return i18n.t('common.ultraPlan');
      default: return '-';
    }
  },
  adDisplayTypeName(str) {
    switch (str) {
      case 'Period': return i18n.t('ad.statusPeriod');
      case 'Enable': return i18n.t('ad.statusEnable');
      case 'Disable': return i18n.t('ad.statusDisable');
      default: return '-';
    }
  },
  noticeTypeName(str) {
    switch (str) {
      case 'General': return i18n.t('common.general');
      case 'Urgent': return i18n.t('common.urgent');
      default: return '-';
    }
  },
  termsTypeName(str) {
    switch (str) {
      case 'Member': return i18n.t('terms.typeMember');
      case 'Card': return i18n.t('terms.typeCard');
      case 'Plan': return i18n.t('terms.typePlan');
      case 'Quit': return i18n.t('terms.typeQuit');
      default: return '-';
    }
  },
  agreeOptionName(str) {
    switch (str) {
      case 'Required': return i18n.t('common.essential');
      case 'Optional': return i18n.t('common.choice')
      default: return '-';
    }
  },
  notificationTypeName(str) {
    switch (str) {
      case 'Payment': return i18n.t('notification.payment');
      case 'ChargePoint': return i18n.t('notification.cp');
      default: return '-';
    }
  },
  // word1, word2 를 이용하여 Language 반환
  convertedTitle(word1, word2) {
    return i18n.t(`${word1}.${word2}`).toString();
  },
  // 소문자로 변환
  toLowerCaseString(str) {
    return str.toLowerCase();
  },
  // to YYYY-MM-DD
  timestampToDate(string) {
    return string.substring(0, 11);
  },
  // 충전기 상태변 텍스트 색상 반환
  cbStatusTextColor(status) {
    // const status = this.$_app.code.chargePointStatus[value] ?? value;
    if (status ==='Available') {
      return 'text-success';
    } else if (status ==='Charging') {
      return 'text-primary';
    } else {
      return 'text-secondary';
    }
  }
};

export default {
  install(Vue) {
    Vue.prototype.$_formatter = formatter;
  }
}
